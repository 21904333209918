.entryView {
   color: rgba(255,255,255,0.9);

   &.inReplyContext {
      color: rgba(255,255,255,0.5);
   }
}

.entryBody {
   font-size: 48px;
   line-height: 64px;
   letter-spacing: -0.5px;

   ol {
      margin: 0 0 0 20px;
      padding: 0;
   }
   
   ul {
      margin: 0 0 0 20px;
      padding: 0;
   }

   li {
      position: relative;
      padding: 0 0 0px 10px;
      margin: 0;
      font-size: 0.95em;
   }

   .inReplyContext & {
      font-size: 32px;
      line-height: 48px;
   }
}

.stats {
   display: block;
   color: rgba(255,255,255,0.4);
   font-size: 22px;
   line-height: 28px;
   padding-top: 30px;

   .inReplyContext & {
      font-size: 16px;
      line-height: 20px;
   }

   .stat {
      display: inline-block;
      margin-right: 48px;
   }

   .hideStat {
      display: none;
   }
}


// =======================================================
// Animations
// =======================================================

@keyframes animateInMessage {
   0% { opacity: 0; transform: translateY(5%); }
   100% { opacity: 1; transform: translateY(0%); }
}

@keyframes animateOutMessage {
   0% { opacity: 0; }
   100% { opacity: 0; }
}

.animateInMessage {
   opacity: 1;
   animation-duration: 1000ms;
   animation-name: animateInMessage;
   animation-timing-function: ease-out;
}

.animateOutMessage {
   opacity: 0;
   animation-name: animateOutMessage;
   animation-timing-function: ease-out;
   animation-duration: 200ms;
}

.animateInReply {
   opacity: 1;
   animation-duration: 1300ms;
   animation-name: animateInReply;
   animation-timing-function: ease-out;
}

.animateOutReply {
   opacity: 0;
   animation-name: animateOutReply;
   animation-duration: 200ms;
}

@keyframes animateInReply {
   0% { opacity: 0; transform: translateY(5%); }
   100% { opacity: 1; transform: translateY(0%); }
}

@keyframes animateOutReply {
   0% { opacity: 0; }
   100% { opacity: 0; }
}
