.theme-mother-co {
   // .discord;
   // .dark-mode;
   // --gatherAppPaddingHorizontal: 90px;
   // --bodyBackgroundColor: #f4f2f2;
   // --titleTextColor: #fff;
   // --bodyTextSize: 13px;

   // --mainMenuBackgroundColor: #fafafa;
   // --mainMenuLineColor: transparent;
   // --mainMenuWidth: 320px;
   // --paragraphTextSize: 17px;
   // --paragraphTextFontFamily: georgia;
   // .gather-aside { display: none; }

   @media screen and (min-width: 1350px) {
      // --gatherAppPaddingHorizontal: 148px;
      // --gatherAppPaddingHorizontal: 60px;
   }
}
