.returnBar {
   overflow: hidden;

   .title {
      animation: animateIn 900ms ease-out;

      &:hover {
         transition: 300m;
         opacity: 0.7;
      }
   }
}

@keyframes animateIn {
   0% {
      opacity: 0;
      transform: translate3d(-9px, 0, 0);
   }

   50% {
      opacity: 0;
      transform: translate3d(-9px, 0, 0);
   }

   100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
   }
}
