.featureTitle {
   font-size: 18px;
   line-height: 24px;
   font-weight: 550;
   color: #fff;
   margin: 8px 0px 4px 0px;
   // background: -webkit-linear-gradient(#fff, rgba(255,255,255,0.7));
   // -webkit-background-clip: text;
   // -webkit-text-fill-color: transparent;

   .highlightOrange {
      // color: #ef6c2d;
      color: #ff6200;
      // background: -webkit-linear-gradient(#a82cff, #9124e0);
   }
   
   .highlightPurple {
      color: #a82cff;
      // background: -webkit-linear-gradient(#a82cff, #9124e0);
   }

   .highlightBlue {
      color: #616dfb;
      // background: -webkit-linear-gradient(#616dfb, #2f3abb);
   }
}

.featureSubtitle {
   font-size: 15px;
   line-height: 20px;
   font-weight: 550;
   color: rgba(255,255,255,0.5);
   margin-bottom: 24px;
}
