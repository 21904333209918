.ui-tooltip-content {
   border-radius: 4px;
   padding: 8px 12px;
   font-size: 13px;
   font-family: 'Inter';
   line-height: 1;
   color: #fff;
   background-color: rgba(30,30,30,0.9);
   box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
   user-select: none;
   animation-duration: 400ms;
   animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
   will-change: transform, opacity;
   z-index: var(--tooltipZindex);
 }
 .ui-tooltip-content[data-state='delayed-open'][data-side='top'] {
   animation-name: slideDownAndFade;
 }
 .ui-tooltip-content[data-state='delayed-open'][data-side='right'] {
   animation-name: slideLeftAndFade;
 }
 .ui-tooltip-content[data-state='delayed-open'][data-side='bottom'] {
   animation-name: slideUpAndFade;
 }
 .ui-tooltip-content[data-state='delayed-open'][data-side='left'] {
   animation-name: slideRightAndFade;
 }
 
 .ui-tooltip-arrow {
   fill: rgba(30,30,30,0.9);
 }
 
 @keyframes slideUpAndFade {
   from {
     opacity: 0;
     transform: translateY(2px);
   }
   to {
     opacity: 1;
     transform: translateY(0);
   }
 }
 
 @keyframes slideRightAndFade {
   from {
     opacity: 0;
     transform: translateX(-2px);
   }
   to {
     opacity: 1;
     transform: translateX(0);
   }
 }
 
 @keyframes slideDownAndFade {
   from {
     opacity: 0;
     transform: translateY(-2px);
   }
   to {
     opacity: 1;
     transform: translateY(0);
   }
 }
 
 @keyframes slideLeftAndFade {
   from {
     opacity: 0;
     transform: translateX(2px);
   }
   to {
     opacity: 1;
     transform: translateX(0);
   }
 }
