// const breakpoints = {
//    a: 475,
//    b: 675,
//    c: 1000,
//    d: 1350,
//    e: 1700,
//    f: Infinity
// }

:global {
   @import './constants/index.less';
   @import './constants/layouts.less';
   @import './constants/settings.less';
   @import './constants/system.less';
   @import './constants/typography.less';
   @import './constants/zIndexes.less';
   @import './custom/index.less';
   @import './foundation/normalize.less';
   @import './gather/editor.less';
   @import './gather/schedule.less';
   @import './typography.less';
   @import './ui/badge.less';
   @import './ui/popover.less';
   @import './ui/tooltip.less';

   body, #oio-container, .ui-modal {
      font-family: var(--bodyFontFamiy);
      font-size: var(--bodyTextSize);
      font-weight: var(--bodyTextWeight);
      letter-spacing: -0.006em;
      line-height: var(--bodyTextLineHeight);
      color: var(--bodyTextColor);
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
   }

   .gather-viewport-titlebar {
      position: fixed;
      top: 0px;
      left: 0px;
      right: 0px;
      width: 100%;
      height: 30px;
      -webkit-user-select: none;
      -webkit-app-region: drag;
      pointer-events: none;
      z-index: var(--electronTitlebarZIndex);
   }

   
   .gather-transparent-header-menu {
      --bodyBackgroundColor: #000;
      --headerMenuBackgroundColor: transparent;
   }

   #gather-sidebar-action-block {
      a {
         color: var(--listMenuButtonTextColor);
      }
   }

   #gather-details-block {
      a {
         color: var(--bodyTextColor);
      }
   }

   .ui-titlebar {
      background-color: var(--bodyBackgroundColorTranslucent);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      z-index: 2;
   }

   .ui-grid-cell {
      &.--full-span {
         grid-column: 1 / -1;
      }
   }

   // .showColumnView {
   //    --mainMenuWidth: 240px !important;
   // }

   :root {
      --gutter: 24px;
      --columnWidth: 80px;
      --doubleGutter: calc(var(--gutter) * 2);
   }

   // .grid {
   //    position: fixed;
   //    top: 0;
   //    left: 0;
   //    padding-left: calc(calc(50vw - calc(var(--columnWidth) * 5) - calc(var(--doubleGutter) * 5)));
   //    bottom: 0;
   //    display: flex;
   //    pointer-events: none;

   //    .column {
   //       flex: 0 0 auto;
   //       width: var(--columnWidth);
   //       margin: 0px var(--gutter);
   //       height: 100%;
   //       // background-color: rgba(255,0,0,0.1);
   //    }
   // }

   // .baseline-grid {
   //    position: fixed;
   //    top: 0;
   //    left: 0;
   //    right: 0;
   //    bottom: 0;
   //    // background: linear-gradient(to bottom, rgba(252, 202, 126, 0.6) 1px, transparent 1px);
   //    background-size: 100% 8px;
   //    pointer-events: none;
   // }

   .col-container {
      padding-left: calc(calc(50vw - calc(var(--columnWidth) * 5) - calc(var(--doubleGutter) * 5)));
   }

   .col-3 {
      width: calc(calc(2 * calc(var(--columnWidth) + var(--doubleGutter))));
      // padding: 0px var(--gutter);
   }

   .col-6 {
      width: calc(calc(6 * calc(var(--columnWidth) + var(--doubleGutter))));
      padding: 0px var(--gutter);
   }

   .col-9 {
      width: calc(calc(8 * calc(var(--columnWidth) + var(--doubleGutter))));
      padding: 0px var(--gutter);
   }
}
