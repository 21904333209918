.drawerMenu {
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-color: rgba(20,20,20,0.98);
   pointer-events: none;
   backdrop-filter: blur(4px);
   -webkit-backdrop-filter: blur(4px);
   transition: 300ms;
   opacity: 0;
   padding: 120px 0px 0px 0px;
   font-size: 18px;
   display: flex;
   justify-content: center;

   &.visible {
      opacity: 1;
      pointer-events: all;
   }
}

.drawerMenuButton {
   width: 20px;
   height: 20px;
   position: relative;
   -webkit-transform: rotate(0deg);
   -moz-transform: rotate(0deg);
   -o-transform: rotate(0deg);
   transform: rotate(0deg);
   -webkit-transition: .5s ease-in-out;
   -moz-transition: .5s ease-in-out;
   -o-transition: .5s ease-in-out;
   transition: .5s ease-in-out;
   cursor: pointer;
   z-index: 9999;
   margin-top: 6px;
 }
 
 .drawerMenuButton span {
   display: block;
   position: absolute;
   height: 2px;
   width: 20px;
   background: #aaa;
   opacity: 1;
   left: 0;
   -webkit-transform: rotate(0deg);
   -moz-transform: rotate(0deg);
   -o-transform: rotate(0deg);
   transform: rotate(0deg);
   -webkit-transition: .25s ease-in-out;
   -moz-transition: .25s ease-in-out;
   -o-transition: .25s ease-in-out;
   transition: .25s ease-in-out;
 }
 
 .drawerMenuButton span:nth-child(1) {
   top: 0px;
   -webkit-transform-origin: left center;
   -moz-transform-origin: left center;
   -o-transform-origin: left center;
   transform-origin: left center;
 }
 
 .drawerMenuButton span:nth-child(2) {
   // top: 18px;
   top: 5px;
   -webkit-transform-origin: left center;
   -moz-transform-origin: left center;
   -o-transform-origin: left center;
   transform-origin: left center;
 }
 
 .drawerMenuButton span:nth-child(3) {
   // top: 36px;
   top: 11px;
   -webkit-transform-origin: left center;
   -moz-transform-origin: left center;
   -o-transform-origin: left center;
   transform-origin: left center;
 }
 
 .drawerMenuButton.open span:nth-child(1) {
   -webkit-transform: rotate(45deg);
   -moz-transform: rotate(45deg);
   -o-transform: rotate(45deg);
   transform: rotate(45deg);
   // top: -3px;
   // left: 8px;
   top: 0px;
   left: 3px;
 }
 
 .drawerMenuButton.open span:nth-child(2) {
   width: 0%;
   opacity: 0;
 }
 
 .drawerMenuButton.open span:nth-child(3) {
   -webkit-transform: rotate(-45deg);
   -moz-transform: rotate(-45deg);
   -o-transform: rotate(-45deg);
   transform: rotate(-45deg);
   // top: 39px;
   // left: 8px;
   top: 14px;
   left: 3px;
 }
