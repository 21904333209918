@font-face {
   font-family: 'switzer';
   src: url('/assets/fonts/switzer/switzer.ttf') format('truetype'),
      url('/assets/fonts/switzer/switzer.woff2') format('woff2 supports variations'),
      url('/assets/fonts/switzer/switzer.woff2') format('woff2-variations');
   font-weight: 100 1000;
}

@font-face {
   font-family: 'erode';
   src: url('/assets/fonts/erode/erode.ttf') format('truetype');
   font-weight: 100 1000;
}

@font-face {
   font-family: 'garamond';
   src: url('/assets/fonts/ebgaramond/garamond.ttf') format('truetype'),
   url('/assets/fonts/ebgaramond/garamond.woff2') format('woff2 supports variations'),
   url('/assets/fonts/ebgaramond/garamond.woff2') format('woff2-variations');
   font-weight: 100 1000;
}

// @font-face {
//    font-family: 'garamond';
//    src: url('/assets/fonts/garamond/garamond-medium.ttf') format('truetype');
//    font-weight: 500;
// }
//
// @font-face {
//    font-family: 'garamond';
//    src: url('/assets/fonts/garamond/garamond-semibold.ttf') format('truetype');
//    font-weight: 600;
// }

@font-face {
   font-family: 'merriweather';
   src: url('/assets/fonts/merriweather/merriweather-regular.ttf') format('truetype');
   font-weight: 400;
}

@font-face {
   font-family: 'roboto-mono';
   src: url('/assets/fonts/roboto-mono/roboto-mono.ttf') format('truetype');
   font-weight: 100 1000;
}

@font-face {
   font-family: 'work-sans';
   src: url('/assets/fonts/work-sans.ttf') format('truetype');
   font-weight: 100 1000;
}

// Constants Only
// Below will allow for global font-size settings
.typography-small {
   --paragraphTextFontSize: 14px;
   --paragraphTextLineHeight: 25px;

   .ui-title.lg {
      font-size: 20px;
   }
}

.typography-medium {
   --bodyTextFontSize: 12px;
   --bodyTextLineHeight: 19px;
   --paragraphTextFontSize: 15px;
   --paragraphTextLineHeight: 26px;

   // For serif font
   // --paragraphTextFontSize: 15px;
   // --paragraphTextLineHeight: 28px;
}

// :root {
//    .typography-medium;
// }
