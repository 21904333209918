.questionLabel {
   float: left;
   width: 100%;
   padding: 0 90px 30px 90px;
   text-align: center;
   font-size: 3.8vh;
   line-height: 1.2em;
   font-weight: 550;
   color: #fff;
}

.bar {
   width: 0px;
   height: 120px;
   transition: 300ms;
   background: #25b668;
   min-width: 6px;
}

.barPercentageLabel {
   position: absolute;
   top: 50%;
   right: -60px;
   margin-top: -12px;
   color: rgba(255,255,255,0.7);
   font-size: 3vh;
   line-height: 3.1vh;
}

.answerLabelContainer {
   display: flex;
   justify-content: flex-end;
   align-items: center;
   width: 100%;
   height: 100%;
   padding-right: 40px;
}

.answerLabel {
   color: rgba(255,255,255,0.8);
   text-align: right;
   font-size: 3vh;
   line-height: 3.5vh;
   max-height: calc(2 * 3.5vh);
   overflow: hidden;
   text-overflow: ellipsis;
}

.manyAnswers {
   .bar {
      height: 50px;
   }

   .answerLabelContainer {
      justify-content: flex-start;
   }

   .answerLabel {
      text-align: left;
      font-size: 2.8vh;
      line-height: 3vh;
      max-height: calc(1 * 3vh);
   }
}
