@highlightedMessageNumberColor: #25b668;

@keyframes showMessage {
   0% { opacity: 0; transform: translatex(-2%); }
   100% { opacity: 1; transform: translateY(0); }
}

.message {
   animation-duration: 1000ms;
   animation-name: showMessage;
   animation-timing-function: ease-out;
   -webkit-backface-visibility: hidden;
   transition: 300ms opacity, transform;
   position: relative;
   padding: 48px 30px 56px 150px;
   color: #FFFFFF;

   &:last-child {
      margin-bottom: 120px;

      .line { border: none; }
   }

   .line {
      position: absolute;
      bottom: 0;
      left: 150px;
      right: 30px;
      border-bottom: 4px solid rgba(255,255,255,0.05);
   }
}

.messageNumber {
   position: absolute;
   top: 40px;
   left: 24px;
   height: 64px;
   width: 64px;
   display: flex;
   justify-content: center;
   align-items: center;
   letter-spacing: -1px;
   line-height: 30px;
   padding-bottom: 2px;
   padding-right: 2px;
   text-align: center;
   border-radius: 50%;
   transition: 400ms background, border;
   font-weight: 400;
   font-size: 30px;
   background-color: #414648;
   // color: #c6d4e2;
   color: #fff;
}

.messageBody {
   font-size: 48px;
   line-height: 64px;
   letter-spacing: -0.5px;

   ol {
      margin: 0 0 0 20px;
      padding: 0;
   }
   
   ul {
      margin: 0 0 0 20px;
      padding: 0;
   }

   li {
      position: relative;
      padding: 0 0 0px 10px;
      margin: 0;
      font-size: 0.95em;
   }
}

.messageDetails {
   color: rgba(255,255,255,0.5);
   font-size: 18px;
   line-height: 28px;

   .stat {
      display: inline-block;
      padding-top: 24px;
      margin-right: 56px;
   }

   .hideStat {
      display: none;
   }
}

// =======================================================
// Display Modes
// =======================================================

.highlighted {
   height: 120px;
   transition: 400ms;

   .messageNumber {
      border: none;
      line-height: 58px;
      top: 72px;
      transform: scale(1.4);
      color: #fff;
      background-color: @highlightedMessageNumberColor;
   }
}

.minimized {
   padding: 36px 0px 36px 120px;
   height: 36px;
   border: none !important;

   .messageNumber {
      transform: scale(0.7);
      background-color: #49565e;
      color: #b5cce3;
      font-weight: normal;
   }
   .messageBody, .messageDetails {
      display: none;
   }
}
