.ui-badge {
   display: flex;
   justify-content: center;
   align-items: center;
   text-align: center;
   height: 18px;
   min-width: 18px;
   line-height: 18px;
   border-radius: 9px;
   padding: 0px 4px;
   font-size: 11px;
}
