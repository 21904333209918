.viewTopGradient {
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   height: 120px;
   z-index: 99;
   // background: linear-gradient(0deg, rgba(33, 39, 45, 0), rgba(33, 39, 45, 1));
   background: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
}

// =======================================================
// Feed Detail View Container
// =======================================================

.detailViewContainer {
   position: fixed !important;
   background-color: #21272d;
   background-color: #000;
   font-weight: 300;

   &.isHidden {
      transform: translateX(100%);
   }

   &.isVisible {
      transform: translateX(0%);
   }
}

// =======================================================
// Null Feed
// =======================================================

@keyframes pulsingAnimation {
   0% { background: rgba(255,255,255,0.05); }
   50% { background: rgba(255,255,255,0.1); }
   100% { background: rgba(255,255,255,0.05); }
}

.nullFeedMessage {
   padding-bottom: 60px;
   background: linear-gradient(-180deg, #1a1c20, #3f4e50);
   text-align: center;
   color: rgba(255,255,255,0.7);
   font-size: 20px;
   line-height: 32px;
   z-index: 100;

   .icon {
      width: 210px;
      height: 210px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin-bottom: 18px;
      animation-name: pulsingAnimation;
   	animation-duration: 5000ms;
   	animation-iteration-count: infinite;
   	animation-timing-function: linear;

      img {
         display: inline-block;
         width: 90px;
         margin: 60px;
         opacity: 0.5;
      }
   }
}

// =======================================================
// New Message Indicator - Bouncing Arrow
// =======================================================

@keyframes pulseIndicator {
   from, 20%, 53%, 80%, to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
      animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
      -webkit-transform: translate3d(0,0,0);
      transform: translate3d(0,0,0);
   }

   40%, 43% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
      animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
      -webkit-transform: translate3d(0, 30px, 0);
      transform: translate3d(0, 30px, 0);
   }

   70% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
      animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
      -webkit-transform: translate3d(0, 15px, 0);
      transform: translate3d(0, 15px, 0);
   }

   90% {
      -webkit-transform: translate3d(0,4px,0);
      transform: translate3d(0,4px,0);
   }
}

.newMessageIndicator {
   position: fixed !important;
   animation-name: pulseIndicator;
   animation-duration: 2000ms;
   animation-iteration-count: infinite;
   animation-timing-function: linear;
   z-index: 100;
   transition: 0.5s;

   &.isHidden {
      opacity: 0;
   }

   &.isVisible {
      opacity: 1;
   }
}
