.gather-schedule-date-button {
   border-radius: 6px;
   position: relative;
   display: block;
   width: 100%;
   padding-top: 100%;
   color: var(--bodyTextColor);

   &.active {
      background-color: var(--listMenuButtonActiveBackgroundColor);
      color: var(--highlightColor);
      .weight-semibold;
   }

   &:hover {
      background-color: var(--listMenuButtonActiveBackgroundColor);
   }

   .inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
   }

   .dayOfWeek {
      text-transform: uppercase;
      font-size: 11px;
      line-height: 12px;
   }

   .dateOfMonth {
      font-size: 16px;
   }
}

.gather-schedule-filter-control {
   display: flex;
   justify-content: space-between;
   align-items: center;
   flex: 1 1 auto;
   padding: 0px 8px;
   height: 32px;
   border-radius: 4px;
   color: var(--titleTextColor);
   border: 1px solid var(--primaryLineColor);
   cursor: pointer;

   &:hover {
      border: 1px solid var(--highlightColor);
      color: var(--highlightColor);
   }
}
