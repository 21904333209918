.resumeButtonContainer {
   animation: animateIn 400ms ease-out;
}

.resumeButton {
   font-weight: 600;
   font-size: var(--bodyTextSize);
   padding: 10px 24px;
   border-radius: 24px;
   color: #fff;
   animation-name: pulsingAnimation;
	animation-duration: 3000ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
   cursor: pointer;
   box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
}

@keyframes animateIn {
   0% {
      opacity: 0;
      transform: translate3d(0, 10px, 0);
   }

   100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
   }
}

@keyframes pulsingAnimation {
	0% { background-color: #2acb96; }
	50% { background-color: #086b60; }
	100% { background-color: #2acb96; }
}
