.view {
   display: flex;
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 300;
   background: linear-gradient(0, rgba(20,30,34,0.0) 0%, rgba(10,20,24,0.98) 60%);
   z-index: 111;
   opacity: 0;
   transition: 300ms;

   &.overlayIsVisible {
      opacity: 1;
   }

   .button {
      cursor: pointer;
      transition: 300ms;
      opacity: 0.8;

      &:hover {
         opacity: 1;
      }
   }
}
