@sand1: hsl(50, 20.0%, 99.0%);
@sand2: hsl(60, 7.7%, 97.5%);
@sand3: hsl(59, 6.5%, 95.1%);
@sand4: hsl(58, 6.1%, 92.9%);
@sand5: hsl(57, 6.0%, 90.7%);
@sand6: hsl(56, 5.9%, 88.4%);
@sand7: hsl(55, 5.9%, 85.2%);
@sand8: hsl(51, 6.0%, 77.1%);
@sand9: hsl(50, 2.0%, 55.7%);
@sand10: hsl(55, 1.7%, 51.9%);
@sand11: hsl(50, 2.0%, 33.1%);
@sand12: hsl(50, 6.0%, 10.0%);


:root {
   // Highlight Color
   --highlightColor: rgba(var(--highlightColorVar), 1);
   // Colors
   // --asideBackgroundColor: #fcfcfc;
   --avatarPlaceholderBackgroundColor: #f5f5f4;
   --avatarPlaceholderTextColor: #a8a29e;
   // --bodyBackgroundColor: #fcfcfc;
   --bodyBackgroundColor: #fff;
   --bodyBackgroundColorTranslucent: rgba(255,255,255,0.9);
   // --bodyTextColor: rgb(94, 100, 108);
   --bodyTextColor: #46474E;
   --secondaryTextColor: @sand11;
   // --columnLineColor: #F7F7F8;
   --columnLineColor: @sand3;
   --headerMenuBackgroundColor: #fff;
   --inputBackgroundColor: @sand2;
   --inputTextColor: #999;
   --linkColor: @sand12;
   --listMenuButtonActiveBackgroundColor: #F3F3F2;
   --listMenuButtonActiveBackgroundColor: rgba(var(--highlightColorVar), 0.05);
   --listMenuButtonActiveTextColor: var(--highlightColor);
   --listMenuButtonTextColor: @sand11;
   --mainMenuBackgroundColor: transparent;
   --mainMenuButtonTextColor: @sand11;
   --mainMenuLineColor: @sand4;
   // --metaTextColor: #46474E;
   --metaTextColor: #777;
   --notificationBadgeBackgroundColor: var(--highlightColor);
   --notificationBadgeTextColor: #fff;
   --paragraphTextColor: #46474E;
   --primaryLineColor: @sand4;
   --sidebarMenuBackgroundColor: #fafaf9;
   --sidebarMenuBackgroundColor: #fff;
   --subtitleTextColor: #78716c;
   --stickyHeaderBackgroundColor: #eee;
   --titleTextColor: #1c1917;
   // Components
   --listMenuButtonBorderRadius: 4px;
   --baseComponentBorderRadius-md: 30px;
   --baseComponentHeight-sm: 32px;
   --baseComponentHeight-md: 32px;
   --baseComponentHeight-lg: 40px;
   --baseComponentHeight-xl: 48px;
   --thumbnailImageBorderRadius: 2px;
   // Gather UI constants
   --gatherDraftStatusTextColor: rgb(213, 144, 47);
   --gatherFooterBackgroundColor: @sand2;
}

// =======================================================
// Dark Mode
// =======================================================

.dark-mode {
   @sand1: hsl(61, 2.0%, 8.3%);
   @sand2: hsl(60, 3.7%, 10.6%);
   @sand3: hsl(58, 3.7%, 13.1%);
   @sand4: hsl(57, 3.6%, 15.3%);
   @sand5: hsl(56, 3.7%, 17.4%);
   @sand6: hsl(55, 3.7%, 19.9%);
   @sand7: hsl(53, 3.7%, 23.6%);
   @sand8: hsl(50, 3.8%, 30.6%);
   @sand9: hsl(50, 4.0%, 42.7%);
   @sand10: hsl(52, 3.1%, 48.3%);
   @sand11: hsl(50, 4.0%, 61.8%);
   @sand12: hsl(56, 4.0%, 92.8%);

   --asideBackgroundColor: #080808;
   --avatarPlaceholderBackgroundColor: #333;
   --avatarPlaceholderTextColor: #aaa;
   --bodyBackgroundColor: #0c0c0c;
   --bodyBackgroundColorTranslucent: rgb(12, 12, 12, 0.9);
   --bodyTextColor: #aaa;
   --secondaryTextColor: @sand11;
   --headerMenuBackgroundColor: #0c0c0c;
   --columnLineColor: #181818;
   --inputBackgroundColor: #1a1a1a;
   --inputTextColor: #999;
   --linkColor: @sand12;
   --listMenuButtonActiveBackgroundColor: #222;
   --listMenuButtonActiveTextColor: #fff;
   --listMenuButtonTextColor: @sand11;
   --mainMenuBackgroundColor: transparent;
   --mainMenuButtonTextColor: @sand11;
   --mainMenuLineColor: @sand4;
   --metaTextColor: #888;
   --notificationBadgeBackgroundColor: orange;
   --notificationBadgeTextColor: #000;
   --paragraphTextColor: #aaa;
   --primaryLineColor: #1c1c1c;
   --sidebarMenuBackgroundColor: #0c0c0c;
   --subtitleTextColor: #46474E;
   --stickyHeaderBackgroundColor: #222;
   --titleTextColor: #fff;
   // Gather UI constants
   --gatherDraftStatusTextColor: rgb(213, 144, 47);
   --gatherFooterBackgroundColor: @sand2;
}

.discord {
   // Highlight Color
   --highlightColor: rgba(var(--highlightColorVar), 1);
   // Colors
   // --asideBackgroundColor: #fcfcfc;
   --avatarPlaceholderBackgroundColor: #f5f5f4;
   --avatarPlaceholderTextColor: #a8a29e;
   // --bodyBackgroundColor: #fcfcfc;
   --bodyBackgroundColor: #2A2C30;
   --bodyBackgroundColorTranslucent: rgba(42, 44, 48, 0.9);
   // --bodyTextColor: rgb(94, 100, 108);
   --bodyTextColor: #B5BAC1;
   --secondaryTextColor: #9b9fa5;
   // --columnLineColor: #F7F7F8;
   --columnLineColor: #1E1F22;
   --headerMenuBackgroundColor: #1E1F22;
   --inputBackgroundColor: #1E1F22;
   --inputTextColor: #B5BAC1;
   --linkColor: #fff;
   --listMenuButtonActiveBackgroundColor: #404249;
   --listMenuButtonActiveTextColor: #fff;
   --listMenuButtonTextColor: #949BA4;
   --mainMenuBackgroundColor: transparent;
   --mainMenuLineColor: #1E1F22;
   --metaTextColor: #9b9fa5;
   --notificationBadgeBackgroundColor: var(--highlightColor);
   --notificationBadgeTextColor: #fff;
   --paragraphTextColor: #B5BAC1;
   --primaryLineColor: #1E1F22;
   --sidebarMenuBackgroundColor: #25262a;
   --subtitleTextColor: #78716c;
   --titleTextColor: #fff;
   // Components
   --listMenuButtonBorderRadius: 4px;
   --baseComponentBorderRadius-md: 30px;
   --baseComponentHeight-sm: 32px;
   --baseComponentHeight-md: 32px;
   --baseComponentHeight-lg: 40px;
   --baseComponentHeight-xl: 48px;
   --thumbnailImageBorderRadius: 2px;
   // Gather UI constants
   --gatherDraftStatusTextColor: rgb(213, 144, 47);
}
