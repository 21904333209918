.formBuilderCard {
   overflow: hidden;
   font-family: neue-haas, "Helvetica Neue", Helvetica, sans-serif;
   -moz-user-select: none;
   -khtml-user-select: none;
   -webkit-user-select: none;
   -ms-user-select: none;
   user-select: none;

   &:hover {
      .preview {
         display: none;
      }

      input[type=text], textarea {
         background: rgba(250, 147, 104, 0.1);
      }
      .singleLineText {
         opacity: 0.5;
      }
   }

   input[type=text], textarea {
      border: none;
      transition: background-color 300ms;
      margin: 0;
   }

   .preview {
      display: block;
   }

   .singleLineText {
      transition: 300ms;
   }
}
