
// =======================================================
// Sidebar Layout Constants
// =======================================================

.gather-layout-sidebar {
   --gatherAppPaddingHorizontal: 24px;
   --mainMenuWidth: 250px;

   @media screen and (min-width: 675px) {
      --gatherAppPaddingHorizontal: 24px;
   }

   @media screen and (min-width: 1350px) {
      --gatherAppPaddingHorizontal: 24px;
   }
}

// =======================================================
// Box Layout Constants
// =======================================================

.gather-layout-box {
   --gatherAppPaddingHorizontal: 20px;
   --mainMenuWidth: 270px;

   @media screen and (min-width: 675px) {
      --gatherAppPaddingHorizontal: 40px;
   }

   @media screen and (min-width: 1350px) {
      --gatherAppPaddingHorizontal: 48px;
   }
}

// =======================================================
// Header Layout Constants
// =======================================================

.gather-layout-header {
   --gatherAppPaddingHorizontal: 20px;

   @media screen and (min-width: 675px) {
      --gatherAppPaddingHorizontal: 40px;
   }

   @media screen and (min-width: 1350px) {
      --gatherAppPaddingHorizontal: 0px;
   }
}
