:global {
   .rc-time-picker-panel {
       font-family: 'Neue Haas', 'Helvetica Neue', sans-serif !important;
       font-size: 13px;
       color: #444;
   }

   .rc-time-picker-input {
      height: 30px;
      font-size: 13px;
      border: none;
      padding: 4px 24px 4px 9px;
      background-color: #eee;
      color: #222;
   }

   .rc-time-picker-panel-input,
   .rc-time-picker-panel-select {
      font-size: 13px;
   }

   .rc-time-picker-clear {
      top: 1px;
   }
}
