html {
   -webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
   box-sizing: border-box;
 }
 
body {
   margin: 0;
   padding: 0;
}

input {
   -webkit-appearance: none;
   border: none;
   outline: none;
}

button {
   unset: all;
   -webkit-appearance: none;
   background: none;
   border: none;
   outline: none;
   user-select: none;
   margin: 0;
   padding: 0;
}

*, *:before, *:after {
   -webkit-box-sizing: inherit;
   -moz-box-sizing: inherit;
   box-sizing: inherit;
}

h1, h2, h3, p {
   margin: 0;
   padding: 0;
}
 
a {
   text-decoration: none;
   color: inherit;
}
