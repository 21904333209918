.ui-popover {
   background-color: rgba(46, 45, 51, 0.9);
   width: 100%;
   max-height: 400px;
   border-radius: 7px;
   -webkit-backdrop-filter: blur(10px);
   backdrop-filter: blur(10px);
   padding: 4px 4px;
   box-shadow: 6px 6px 30px rgba(0,0,0,0.05);
   font-size: 13px;
   color: #888;
   overflow: auto;
   --listMenuButtonBorderRadius: 2px;
   --listMenuButtonActiveBackgroundColor: rgba(255,255,255,0.2);
   --listMenuButtonActiveTextColor: #fff;
   --listMenuButtonTextColor: #eee;
}

.ui-popover-seperator {
   width: 100%;
   height: 1px;
   background-color: rgba(255,255,255,0.1);
}
