/**
 * @Author: ajay
 * @Date:   Aug 25, 2022
 * @Last modified by:   ajay
 * @Last modified time: Aug 25, 2022
 */
.subscriptionAgreement {
   ul {
      margin-left: 20px;
   }

   table th {
      padding-right: 12px;
   }

   table td {
      padding-right: 12px;
   }
}
