/**
 * @Last modified by:   ajay
 * @Last modified time: Jun 6, 2022
 */

:global {
   @import '../../../../core/style/constants/typography.less';
   @import '../../../../core/style/ui/buttons.less';

   .mother-theme {
      --borderColor: #ddd;
      --buttonBorderRadius: 4px;
      --buttonHeight: 30px;
      --formFieldBackgroundColor: rgba(0,0,0,0.05);
      --textColor: #444;
      font-family: 'Switzer', sans-serif;
      font-size: 15px;
      color: #444;
   }

   .mother-description-text {
      font-size: 11px;
      line-height: 17px;
      text-transform: uppercase;
   }

   .mother-date-detail {
      display: flex;
      align-items: center;

      .date {
         flex: 0 0 auto;
         padding-right: 10px;
         font-size: 11px;
         line-height: 17px;
         color: #444;
         text-transform: uppercase;
      }

      .line {
         flex: 1 1 auto;
         margin-top: 4px;
         border-top: 1px solid var(--borderColor);
      }
   }
}
