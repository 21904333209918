/* Gather Froala Theme
Unforunately, froala doesn't let us theme in a nice way, so all of these styles
are overrideing Froala's default Royal theme and must use !important
 */
 .gather-theme .fr-element.fr-view {
   font-family: inherit !important;
   font-size: inherit !important;
   line-height: inherit !important;
   color: inherit !important;
}

.gather-theme .fr-wrapper {
    border: 1px solid #eee !important;
    border-top: none !important;
}

.gather-theme .fr-placeholder {
   padding: 20px !important;
}

.gather-theme .fr-toolbar {
   background: #fafafa !important;
   border: 1px solid #eee !important;
   border-bottom: none !important;
   border-radius: 3px !important;
}

.gather-theme.fr-inline .fr-wrapper {
    border: 1px solid transparent !important;
}

.gather-theme .fr-btn {
   height: 35px !important;
}

.gather-theme .fr-btn-grp {
   margin: 0px 5px !important;
}

.gather-theme svg {
  line-height: 32px;
  height: 20px !important;
  width: 20px !important;
}

.gather-theme .second-toolbar {
  border: none !important;
}
