// CSS Styles for UI Components
.ui-button {
   display: flex;
   flex: 0 0 auto;
   position: relative;
   left: -10px;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   padding: 0px 10px 2px 10px;
   height: 32px;
   border-radius: var(--buttonBorderRadius);
   cursor: pointer;
   outline: none;
   &:hover {
      background-color: var(--formFieldBackgroundColor);
   }
}

.ui-icon-button {
   display: flex;
   flex: 0 0 auto;
   position: relative;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 32px;
   height: 32px;
   border-radius: var(--buttonBorderRadius);
   cursor: pointer;
   outline: none;
   &:hover {
      background-color: var(--formFieldBackgroundColor);
   }
}
